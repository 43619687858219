import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ADMIN_CALCULATE_PERMIT_PASS_PRICE from "../../../../graphql/permitPass/queries/AdminCalculatePermitPassPrice";
import ADMIN_CALCULATE_PERMIT_PASS_PRICE_V2 from "../../../../graphql/permitPass/queries/AdminCalculatePermitPassPriceV2";
import { formatDate } from "./utils";
import { transformToInteger } from "../../../../shared/services/currencies";

const usePriceCalculatorV2 = ({ apolloClient, formikbag, properties }) => {
  const { values, errors } = formikbag;
  const [hideCalculation, setHideCalculation] = useState(false);

  const {
    amount,
    periodAmount,
    lot,
    startDate,
    startTime,
    endDate,
    endTime,
    typeOfPermit,
    price,
    permitKind,
    permitRecurringType,
  } = values;

  const isRecurring = permitKind === "RECURRING";
  const isCustomRate = price === "customRate";

  const query = isRecurring
    ? ADMIN_CALCULATE_PERMIT_PASS_PRICE_V2
    : ADMIN_CALCULATE_PERMIT_PASS_PRICE;

  const [calculatePriceV2, { data, loading, error }] = useLazyQuery(query, {
    client: apolloClient,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const getShouldCalculate = () => {
      const hasError =
        errors.endDate ||
        errors.endTime ||
        errors.startDate ||
        errors.startTime;

      if (!lot || typeOfPermit !== "paid" || hasError) {
        setHideCalculation(true);
        return false;
      }

      if (isCustomRate && !periodAmount) {
        setHideCalculation(true);
        return false;
      }

      setHideCalculation(false);
      return true;
    };

    const shouldCalculate = getShouldCalculate();

    if (!shouldCalculate) {
      return;
    }
    const lotSelected = properties.find(
      (property) => property.id === parseInt(lot)
    );

    const start = formatDate(startDate, startTime, lotSelected?.timezoneName);
    const end = formatDate(endDate, endTime, lotSelected?.timezoneName);

    calculatePriceV2({
      variables: {
        lotId: values.lot,
        startTime: start,
        endTime: end,
        kind: permitKind,
        ...((amount || periodAmount) &&
          isCustomRate && {
            customPrice: transformToInteger(
              permitKind === "RECURRING" ? periodAmount : amount,
              lotSelected.country.currency
            ),
          }),
        ...(isRecurring && { recurringType: permitRecurringType }),
      },
    });
  }, [
    amount,
    periodAmount,
    lot,
    startDate,
    startTime,
    endDate,
    endTime,
    typeOfPermit,
    price,
    permitKind,
    permitRecurringType,
    errors.endDate,
    errors.endTime,
    errors.startDate,
    errors.startTime,
  ]);

  if (hideCalculation) {
    return {
      data: null,
      loading: false,
      error: null,
    };
  }

  if (isRecurring) {
    return {
      data: data?.admin_calculatePermitPassPriceV2?.fixedPrice?.total,
      breakdown: data?.admin_calculatePermitPassPriceV2?.recurringBreakdown,
      loading,
      error,
    };
  }

  return {
    data: data?.admin_calculatePermitPassPrice?.total,
    loading,
    error,
  };
};

export default usePriceCalculatorV2;

import currencyFormatter from "@citifyd/currency-formatter";

import { EMPTY_AMOUNT } from "../../../constants/static";

export const getAmount = ({ isRecurring, language, permit }) => {
  if (!permit) {
    return EMPTY_AMOUNT;
  }

  const calculatedPrice = isRecurring
    ? permit?.totalCalculatedPrice?.total
    : permit?.calculatedPrice?.total;

  if (calculatedPrice) {
    return currencyFormatter.format(calculatedPrice, {
      currency: "usd",
      language,
    });
  }

  return EMPTY_AMOUNT;
};

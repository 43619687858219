import { permitKind } from "../../../shared/services/permits";

export const getRecurrence = ({ permit, t }) => {
  const RECURRENCE_MAP = {
    WEEKLY: t("permits.information.type.weekly"),
    BIWEEKLY: t("permits.information.type.biweekly"),
    MONTHLY: t("permits.information.type.monthly"),
    THREE_MONTHLY: t("permits.information.type.quarterly"),
    YEARLY: t("permits.information.type.yearly"),
  };

  if (permit.kind !== permitKind.RECURRING) return "—";

  return RECURRENCE_MAP[permit.recurringType] || "—";
};

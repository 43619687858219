import moment from "moment-timezone";
import currencyFormatter from "@citifyd/currency-formatter";

import styles from "./BreakdownTable.module.scss";

export const BreakdownTable = ({ breakdown, currency, language }) => {
  if (!breakdown) return null;

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #ccc",
        }}
      >
        <div className={styles["table-head-cel"]}>Date</div>
        <div className={styles["table-head-cel"]}>Price</div>
      </div>
      {breakdown?.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              borderBottom:
                breakdown.length - 1 === index ? "none" : "1px solid #ccc",
            }}
          >
            <div className={styles["table-body-cel"]}>
              {moment(item.startTime).format("MM/DD/YYYY")}
            </div>
            <div className={styles["table-body-cel"]}>
              <strong>
                {currencyFormatter.format(item.price.total, {
                  currency,
                  language,
                })}
              </strong>
            </div>
          </div>
        );
      })}
    </div>
  );
};

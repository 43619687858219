"use strict";

import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import { formatDate, getDate, getFormattedDate, getTime } from "./utils";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import { transformToInteger } from "../../../../shared/services/currencies";

import ADMIN_CREATE_PERMIT_PASS from "../../../../graphql/permitPass/mutations/AdminCreatePermitPass";
import ADMIN_UPDATE_PERMIT_PASS from "../../../../graphql/permitPass/mutations/AdminUpdatePermitPass";
import { dateAndTimeToMomentObject } from "../../../../shared/services/helper";
import { permitKind } from "../../../../shared/services/permits";

const useAddPermitForm = ({
  apolloClient,
  properties,
  user,
  permit,
  setErrorMessage,
  setHasError,
  setIsLoading,
}) => {
  const t = useTranslator();
  const $state = useService("$state");

  const isEditing = Boolean(permit);

  const [addPermit] = useMutation(ADMIN_CREATE_PERMIT_PASS, {
    client: apolloClient,
  });

  const [editPermit] = useMutation(ADMIN_UPDATE_PERMIT_PASS, {
    client: apolloClient,
  });

  return useFormik({
    initialValues: {
      countryCode: user?.phoneCountryCode || "us",
      phoneNumber: permit
        ? permit.phoneNumber?.length > 2
          ? formatPhoneNumber(permit.phoneNumber, permit.phoneCountryCode)
          : ""
        : "",
      lot: permit ? permit.lot.id : "",
      startDate: getDate({ field: "startTime", permit }),
      startTime: getTime({ field: "startTime", permit }),
      endDate: getDate({ field: "endTime", permit }),
      endTime: getTime({ field: "endTime", permit }),
      frequency: "one",
      typeOfPermit: "free",
      price: "",
      amount: null,
      periodAmount: null,
      permitKind: permitKind.FIXED,
      permitRecurringType: "WEEKLY",
    },
    validationSchema: yup.object().shape({
      countryCode: yup.string().required(),
      phoneNumber: yup.string().required(),
      lot: yup.string().required(),

      startDate: isEditing
        ? yup.date()
        : yup
            .date()
            .required()
            .when(["startTime"], (startTime) => {
              return yup.date().test({
                test: (startDate) => {
                  const start = dateAndTimeToMomentObject(startDate, startTime);
                  return moment(start).isAfter(new Date());
                },
              });
            }),

      endDate: isEditing
        ? yup.date()
        : yup
            .date()
            .required()
            .when(["startDate", "endTime"], (startDate, endTime) => {
              return yup.date().test({
                test: (endDate) => {
                  const end = dateAndTimeToMomentObject(endDate, endTime);

                  return moment(end).isAfter(moment(startDate));
                },
                message: t("permits.permitters.errors.mustBeAfterStartDate"),
              });
            }),

      frequency: yup.string().required(),

      amount: yup
        .string()
        .nullable(true)
        .when(["permitKind", "price"], {
          is: (permitKind, price) =>
            price === "customRate" && permitKind !== "RECURRING",
          then: yup.string().nullable(true).required(),
          otherwise: yup.string().nullable(true),
        }),

      periodAmount: yup
        .string()
        .nullable(true)
        .when(["permitKind", "price"], {
          is: (permitKind, price) =>
            price === "customRate" && permitKind === "RECURRING",
          then: yup.string().nullable(true).required(),
          otherwise: yup.string().nullable(true),
        }),

      permitRecurringType: yup.string().when("permitKind", {
        is: permitKind.RECURRING,
        then: yup.string().required(),
      }),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      const {
        countryCode,
        phoneNumber,
        lot,
        startDate,
        startTime,
        endDate,
        endTime,
        typeOfPermit,
        price,
        amount,
        periodAmount,
        permitKind,
        permitRecurringType,
      } = values;

      const phoneNumberFormatted = phoneNumber?.replace(/\s/g, "");

      const lotSelected = properties.filter(
        (property) => property.id === parseInt(lot)
      )[0];
      const start = formatDate(startDate, startTime, lotSelected?.timezoneName);
      const end = formatDate(endDate, endTime, lotSelected?.timezoneName);

      const payload = {
        lotId: parseInt(lot),
        startTime: start,
        endTime: end,
        kind: permitKind,
      };

      if (permitKind === "RECURRING") {
        payload.recurringType = permitRecurringType;
      }

      try {
        const { data } = permit
          ? await editPermit({
              variables: {
                id: parseInt(permit.id),
                ...payload,
              },
            })
          : await addPermit({
              variables: {
                phoneNumber: phoneNumberFormatted,
                phoneCountryCode: countryCode,
                chargeType: calculateChargeType({ price, typeOfPermit }),
                ...(price === "customRate" && {
                  customPrice: transformToInteger(
                    permitKind === "RECURRING" ? periodAmount : amount,
                    lotSelected.country.currency
                  ),
                }),
                ...payload,
              },
            });

        if (data) {
          $state.go("permit", {
            permitId: permit
              ? data.admin_updatePermitPass?.id
              : data.admin_createPermitPass?.id,
          });
        }
      } catch (err) {
        setErrorMessage(err?.message);
        setHasError(true);
        setIsLoading(false);
      }
    },
  });
};

function calculateChargeType({ price, typeOfPermit }) {
  if (typeOfPermit === "free") {
    return "FREE";
  } else {
    return price === "customRate"
      ? "PAID_USER_CUSTOM_PRICE"
      : "PAID_USER_EXISTING_RATE";
  }
}

export default useAddPermitForm;
